import React from "react";

import { navigate, Link } from "gatsby";
import GrinLogo from "../../../scss/images/logos/grin-logo.svg";

const RevcoreTeamPage = () => {
  const navTo = (e, dest) => {
    e.preventDefault();
    navigate(dest, { state: { showError: false } });
  };

  return (
    <section
      className="has-background-dark"
      style={{ padding_bottom: "140px" }}
    >
      <div class="container has-text-white">
        <div class="columns mb-6 pt-5 is-multiline is-centered">
          <div class="column is-8 has-text-centered">
            <span class="has-text-grey-dark">The Revcore Team</span>
            <h2 class="mt-2 mb-3 is-size-1 is-size-3-mobile has-text-weight-bold">
              Meet the team
            </h2>
            <p class="subtitle has-text-grey">
              Whether it's privacy, security, cryptography or building a cryptocurrency from scratch, we've done it before. Growing soon!
            </p>
          </div>
        </div>
        <div class="columns is-multiline is-centered">
          <div class="column is-12 is-10-desktop">
            <div class="columns is-multiline">
              <div class="column is-6 mb-4">
                <div class="box has-background-light">
                  <div class="is-vcentered columns is-multiline">
                    <div class="column is-5">
                      <GrinLogo/>
                    </div>
                    <div class="column is-7">
                      <h4 class="mb-2 is-size-4 has-text-weight-bold">
                        Michael "Yeastplume" Cordner
                      </h4>
                      <p class="mb-4 has-text-grey">CEO &amp; Founder</p>
                      <p>
                        Much experience in cryptography, identity, as well as a core Grin developer since 2017. Knows how to get software delivered.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-6 mb-4">
                <div class="box has-background-light">
                  <div class="is-vcentered columns is-multiline">
                    <div class="column is-5">
                      <GrinLogo/>
                    </div>
                    <div class="column is-7">
                      <h4 class="mb-2 is-size-4 has-text-weight-bold">
                        You?
                      </h4>
                      <p class="mb-4 has-text-grey">Want to make a difference</p>
                      <p>
                        We're building our team to service Grin and beyond. <Link to="/contact">Contact us for more information</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RevcoreTeamPage;
