import * as React from "react";
import "../scss/bulma-theme.scss";

import Layout from "../components/common/layout";
import RevcoreTeamPage from "../components/frontend/team/team"

const TeamPage = () => {

  
  return (
    <Layout>
      <RevcoreTeamPage/>
    </Layout>
  );
};

export default TeamPage;
